.dragBoxContainer {
  display: block;
  width: 100% !important;
  height: 100% !important;
  border: 1px dashed #d9d9d9 !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  text-align: center !important;
  background: #fafafa !important;
}

.dragBoxContainer:hover {
  border-color: #098ca3 !important;
}

.dragBoxContentContainer {
  display: flex;
  width: 100% !important;
  height: 100% !important;
  padding: 20px;
}

.dragBoxContentInnerContainer {
  margin: auto;
}

.dragBoxContentIcon {
  font-size: 56px;
  margin-bottom: 0;
  color: #098ca3 !important;
}

.dragBoxContentTitle {
  color: #424242;
  margin-bottom: 2px;
}

.dragBoxContentSubTitle {
  color: #9e9e9e;
}

.photoContainer {
  margin-bottom: 38px;
}

.photoContainer:last-child {
  margin-bottom: 0px;
}

@media (min-width: 768px) {
  .photoContainer:last-child {
    margin-bottom: 38px;
  }
}
