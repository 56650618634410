.photoCard {
  width: 100%;
}

.actionContainer {
  padding: 12px 50px;
}

.photoCardCoverImg {
  max-width: 100%;
}

@media (min-width: 768px) {
  .photoCardCover {
    height: 300px;
    background-color: #eeeeee;
    display: flex;
  }
  .photoCardCoverImg {
    max-height: 300px;
    display: block;
    margin: auto;
  }
}
